export const readFileAsBinary = async (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        if (!file.type.includes('text/plain')) {
            alert('Wgrano niepoprawny plik. Akceptowany typ danych to .txt')
            return
        }
        reader.onload = (e) => {
            const content = e.target?.result as string
            resolve(content)
        }
        reader.onerror = (error) => {
            reject(error)
        }
        reader.readAsBinaryString(file)
    })
}

export const readFile = async (file: File, encoding: string): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        if (!file.type.includes('text/plain')) {
            alert('Wgrano niepoprawny plik. Akceptowany typ danych to .txt')
            return
        }
        reader.onload = (e) => {
            const content = e.target?.result as string
            resolve(content)
        }
        reader.onerror = (error) => {
            reject(error)
        }
        reader.readAsText(file, encoding)
    })
}

export const createTextFile = (filename: string, content: string): void => {
    const blob = new Blob([content], { type: 'octet/stream;encoding=cp1250' })
    const url = URL.createObjectURL(blob)

    const a = document.createElement('a')
    a.href = url
    a.download = filename
    a.style.display = 'none'

    document.body.appendChild(a)
    a.click()

    document.body.removeChild(a)
    URL.revokeObjectURL(url)
}
