import storage from 'redux-persist/lib/storage'
import { configureStore } from '@reduxjs/toolkit'
import dataReducer from './slices/dataSlice'
import { persistStore, persistReducer } from 'redux-persist'
import importedDataReducer from './slices/importedDataSlice'

const persistConfig = {
    key: 'data',
    storage,
}

const persistConfigImportedData = {
    key: 'importedData',
    storage,
}

const persistedDataReducer = persistReducer(persistConfig, dataReducer)
const persistedImportedDataReducer = persistReducer(persistConfigImportedData, importedDataReducer)

export const store = configureStore({
    reducer: {
        data: persistedDataReducer,
        importedData: persistedImportedDataReducer
    },
    // middleware: getDefaultMiddleware =>
    //     getDefaultMiddleware().concat(dataReducer.middleware),
    devTools: false // switch to false in production
})

export const persistor = persistStore(store)