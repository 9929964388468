import { IWire } from './models'

export const convertArrayIntoIWire = (data: string[][]): IWire[] => {
    if (data.length === 0) return []
    return data.map((wireArray) => ({
        wireType: Number(wireArray[0]),
        date: wireArray[1],
        amount: Number(wireArray[2]),
        NRB: Number(wireArray[3]),
        value: Number(wireArray[4]),
        senderAccountNumber: wireArray[5],
        receiverAccountNumber: wireArray[6],
        senderName: wireArray[7],
        receiverName: wireArray[8],
        unused: 0,
        receiverNRB: Number(wireArray[10]),
        title: wireArray[11],
        empty1: "",
        empty2: "",
        wireTypeCheck: wireArray[14]
    } as IWire))
}