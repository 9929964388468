import { createSlice } from '@reduxjs/toolkit'
import { IWire } from '../pkg/models'

const initialState: {
    data: IWire[]
} = {
    data: []
}

const importedDataSlice = createSlice({
    name: 'importedData',
    initialState,
    reducers: {
        setImportedData: (state, action) => {
            state.data = action.payload
        }
    }
})

export const { setImportedData } = importedDataSlice.actions

export const selectImportedData = (state: any) => state.importedData.data

export default importedDataSlice.reducer
