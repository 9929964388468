// @ts-ignore
import Papa from 'papaparse'
import { IWire } from 'src/pkg/models'
import * as Yup from 'yup'

const config = { delimeter: ',' }

export const parseToJSON = (csv: string, setResponse: (res: any) => void): void => {
    Papa.parse(
        csv,
        {
            ...config,
            complete: (result: {
                data: string[]
            }) => {
                setResponse(result.data.slice().reverse()[0].length <= 1 ? result.data.slice(0, result.data.length - 1) : result.data)
            }
        }
    )
    return
}

export const parseToCSV = (editedData: IWire[]): string | string[] => {
    try {
        csvSchema.validateSync(editedData, { abortEarly: false })

        return Papa.unparse(editedData, {
            header: false,
            quotes: [
                false,
                false,
                false,
                false,
                false,
                true,
                true,
                true,
                true,
                false,
                false,
                true,
                true,
                true,
                true
            ]
        })
    } catch (error: any) {
        if (Yup.ValidationError.isError(error)) {
            return error.errors
        }
        return ''
    }
}

const csvSchema = Yup.array().of(
    Yup.object({
        date: Yup.string()
            .required('Kolumna data wymaga sprawdzenia')
            .length(8, 'Kolumna data wymaga sprawdzenia'),
        amount: Yup.number()
            .required('Kolumna kwota przelewu wymaga sprawdzenia')
            .positive('Kolumna kwota przelewu wymaga sprawdzenia'),
        receiverAccountNumber: Yup.string()
            .required('Kolumna numer konta odbiorcy wymaga sprawdzenia')
            .length(26, 'Kolumna numer konta odbiorcy wymaga sprawdzenia')
            .matches(/^\d+$/, 'Numer konta powinien zawierać tylko cyfry'),
        receiverName: Yup.string()
            .required('Kolumna kontahent konta wymaga sprawdzenia')
            .matches(/^[ĄąÄäĆćĘęŁłŃńÓóÖößÜüŚśŹźŻżA-Za-z0-9\s(){}\/+'.,!^_%~`=<>@$&#*;?:\[\]|-]{0,70}$/, 'Kolumna kontrahent zawiera niedozwolone znaki'),
        title: Yup.string()
            .required('Kolumna tytuł wymaga sprawdzenia')
            .matches(/^[ĄąÄäĆćĘęŁłŃńÓóÖößÜüŚśŹźŻżA-Za-z0-9\s(){}\/+'.,!^_%~`=<>@$&#*;?:\[\]|-]{0,70}$/, 'Kolumna tytuł zawiera niedozwolone znaki'),
    })
)