'use client'

import { useState, useEffect } from 'react'
import { useTheme } from '@emotion/react'
import './style.scss'

import {
    Box,
    TextField,
    Typography,
} from '@mui/material'
import { useContext } from 'react'
import EditorContext from 'src/context/editorContext'
import { useSelector } from 'react-redux'
import { selectData } from 'src/slices/dataSlice'

const EditorField = (): JSX.Element => {
    const wires = useSelector(selectData)
    const appTheme: any = useTheme()
    const {
        value,
        setValue,
    } = useContext(EditorContext)
    const [sum, setSum] = useState<number>(0)

    useEffect(() => {
        if (!!wires && wires.length > 0) setSum(wires.reduce((acc, current) => {
            return acc + current.amount
        }, 0))
    }, [wires])

    return (
        <Box className={'editor_field_wrapper'} sx={{ backgroundColor: appTheme.palette.primary.main }}>
            <TextField className={'editor_field_text_field'} value={value} onChange={(e) => setValue(e.target.value)} />
            <Box className={'editor_sum_field'}>
                <Typography>Suma: {sum / 100} zł</Typography>
            </Box>
        </Box>
    )
}

export default EditorField