'use client'

import { useState, ChangeEvent, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectData } from 'src/slices/dataSlice'
import { useTheme } from '@emotion/react'
import Logo from '../../../assets/bi_logo.jpg'
import dayjs from 'dayjs'
import axios from 'axios'
import './style.scss'

import { readFile, createTextFile, readFileAsBinary } from '../../../features/fileReader'
import { parseToCSV, parseToJSON } from 'src/features/csvConverter'
import { IWire } from 'src/pkg/models'
import { convertArrayIntoIWire } from 'src/pkg/converters'
import { formatAccountNumber } from 'src/features/formatters'
import { selectImportedData, setImportedData } from 'src/slices/importedDataSlice'

import {
    Box,
    Input,
    Tooltip,
    Button,
    Typography,
} from '@mui/material'

import CheckIcon from '@mui/icons-material/Check'

const Nav = ({ ...props }: { openDialog: () => void, setErrors: (arg: string[]) => void }): JSX.Element => {
    const appTheme: any = useTheme()
    const dispatch = useDispatch()
    const [fileContent, setFileContent] = useState<any[]>()
    const data = useSelector(selectData)
    const importedData = useSelector(selectImportedData)
    const [file, setFile] = useState<File | undefined>(undefined)
    const [encoding, setEncoding] = useState<string>('cp1250')

    const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
        setFile(event.target.files?.[0])
        const formData = new FormData()
        // @ts-ignore
        formData.append('file', event.target.files?.[0], 'file.txt')
        axios.post('https://blueidea-api.onitsoft.com/upload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
            .then((response) => {
                setEncoding(response.data.encoding.replace('windows-', 'cp'))
            })
            .catch((error) => {
                console.error('Error:', error)
            })
    }

    useEffect(() => {
        if (file && encoding) {
            readFile(file, encoding)
                .then((response: string) => {
                    parseToJSON(response, setFileContent)
                })
        }
    }, [file, encoding])

    useEffect(() => {
        if (fileContent && fileContent.length > 0) {
            const parsedCSV: IWire[] = convertArrayIntoIWire(fileContent)

            dispatch(setImportedData(parsedCSV))
        }
    }, [fileContent])

    const handleGenerateCSV = (): void => {
        const csvString: string | string[] = parseToCSV(data)
        const date = new Date()

        if (typeof csvString === 'string') {
            createTextFile(`${dayjs(date).format('YYYYMMDD')}.txt`, csvString)
        } else {
            props.setErrors(csvString)
            props.openDialog()
        }
    }

    return (
        <Box className={'nav_wrapper'}>
            <Box className={'nav_logo'}>
                <img src={Logo} alt={'blue idea logo'} />
            </Box>
            {!!importedData && (
                <Box className={'nav_company'}>
                    <Tooltip title={!!importedData[0] && importedData[0]?.senderName.replace(/\|/g, ' ')}>
                        <Typography>
                            {!!importedData[0]?.senderName ? importedData[0]?.senderName.replace(/\|/g, ' ') : ''}
                        </Typography>
                    </Tooltip>
                    <Typography>
                        {!!importedData[0]?.senderAccountNumber ? formatAccountNumber(importedData[0]?.senderAccountNumber) : ''}
                    </Typography>
                </Box>
            )}
            <Box className={'spacer'} />
            <Button component="label" sx={{ color: appTheme.palette.text.light }}>
                <Input hidden sx={{ display: 'none' }} id={`file`} type={'file'} onChange={handleFileChange} />
                <Typography className={'nav_data_button'}>
                    Wgraj dane
                    {!!file && <CheckIcon />}
                </Typography>
            </Button>
            <Button onClick={handleGenerateCSV} sx={{ color: appTheme.palette.text.light }}>
                <Typography>Pobierz CSV</Typography>
            </Button>
        </Box>
    )
}

export default Nav
