import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IWire } from '../pkg/models'

const initialState: {
    data: IWire[]
} = {
    data: []
}

const dataSlice = createSlice({
    name: 'data',
    initialState,
    reducers: {
        setData: (state, action) => {
            return action.payload
        },
        addEntry: (state: any, action: PayloadAction<IWire>) => {
            state.data.push(action.payload)
        },
        changeValue: (state: any, action: PayloadAction<{ index: number, value: any, key: string }>) => {
            const { index, value, key } = action.payload
            state.data[index][key] = value
        },
        changeRowData: (state: any, action: PayloadAction<{ index: number, value: IWire }>) => {
            const { index, value } = action.payload
            state.data[index] = value
        },
        deleteRow: (state: any, action: PayloadAction<{ index: number }>) => {
            const { index } = action.payload
            state.data.splice(index, 1)
        }
    }
})

export const { setData, addEntry, changeValue, changeRowData, deleteRow } = dataSlice.actions

export const selectData = (state: any): IWire[] => state.data.data

export default dataSlice.reducer
