'use client'

import { useState } from 'react'
import Nav from './components/Nav'
import EditorField from './components/EditorField'
import ContentTable from './components/ContentTableV2'
import { EditorContextProvider } from 'src/context/editorContext'

import './rootStyle.scss'

import {
    Box,
    Dialog,
    Button,
    Typography,
    DialogTitle,
    DialogActions,
    DialogContent,
    DialogContentText
} from '@mui/material'

const Root: React.FC = () => {
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
    const [erros, setErrors] = useState<string[]>([])

    window.onbeforeunload = function () {
        localStorage.clear()
    }

    return (
        <EditorContextProvider>
            <Box className={'root_wrapper'}>
                <Nav openDialog={() => setIsDialogOpen(true)} setErrors={setErrors} />
                <EditorField />
                <ContentTable />
                <Dialog
                    fullWidth
                    maxWidth={'sm'}
                    open={isDialogOpen}
                    onClose={() => setIsDialogOpen(false)}>
                    <DialogTitle>Błąd</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {erros.length > 0 && erros.map((error, index) => (
                                <Typography>
                                    {index + 1}: {error}
                                </Typography>
                            ))}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setIsDialogOpen(false)}>
                            Zamknij
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </EditorContextProvider>
    )
}

export default Root
