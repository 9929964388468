import * as React from 'react'

interface IEditorContext {
    value: string,
    setValue: (newValue: string) => void,
}

const defaultEditorContext: IEditorContext = {
    value: '',
    setValue: () => { },
}

const EditorContext = React.createContext<IEditorContext>(defaultEditorContext)

export const EditorContextProvider = ({ children }: { children: React.ReactNode }) => {
    const [value, setValue] = React.useState<string>('')

    return (
        <EditorContext.Provider value={{ 
            value,
            setValue,
        }}>
            {children}
        </EditorContext.Provider>
    )
}

export default EditorContext