import React from 'react'
import './App.css'
import { createTheme } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { Theme as ITheme, ThemeOptions, ThemeProvider } from '@mui/material'

import Root from './root'

const App: React.FC = () => {
  const appTheme: ITheme = createTheme({
    palette: {
      mode: 'light',
      primary: {
          main: '#3486c7',
          contrastText: '#F6F8FF',
      },
      background: {
          default: '#F6F8FF',
      },
      text: {
          light: '#F6F8FF',
          primary: '#191919',
          secondary: '#191919',
      }
  },
  } as ThemeOptions)

  return (
    <ThemeProvider theme={appTheme}>
      <CssBaseline />
      <Root />
    </ThemeProvider>
  )
}

export default App
