import './style.scss'
import dayjs from 'dayjs'
import { useState, ChangeEvent, useContext, useEffect } from 'react'
import { IWire } from 'src/pkg/models'
import { useDispatch, useSelector } from 'react-redux'
import { selectImportedData } from 'src/slices/importedDataSlice'
import { selectData, addEntry, changeRowData, deleteRow } from 'src/slices/dataSlice'

import {
    Box,
    Table,
    Paper,
    Button,
    Tooltip,
    MenuItem,
    TableRow,
    TableCell,
    TableBody,
    TableHead,
    TextField,
    IconButton,
    TableContainer,
} from '@mui/material'

import InfoIcon from '@mui/icons-material/Info'
import EditIcon from '@mui/icons-material/Edit'
import CloseIcon from '@mui/icons-material/Close'
import CheckIcon from '@mui/icons-material/Check'
import DeleteIcon from '@mui/icons-material/Delete'
import EditorContext from 'src/context/editorContext'

const date = new Date()

const defaultEntry: IWire = {
    wireType: 110,
    date: dayjs(date).format('YYYYMMDD'),
    amount: 0,
    NRB: 0,
    value: 0,
    senderAccountNumber: '',
    receiverAccountNumber: '',
    senderName: '',
    receiverName: '',
    unused: 0,
    receiverNRB: 0,
    title: '',
    empty1: "",
    empty2: "",
    wireTypeCheck: '51'
}

interface CellProps {
    value: string | number,
    reduxKey: string,
    index: number,
    bonusValue?: any,
    isEditable: boolean,
    setSelectedCell: (key: string) => void,
    setValue: (valueKey: string, value: string | number) => void,
    handleCancel: () => void,
    handleSetRow: (arg: any) => void,
}

const Cell = ({ ...props }: CellProps): JSX.Element => {
    const { setValue } = useContext(EditorContext)
    const importedData = useSelector(selectImportedData)
    const [isCustomName, setIsCustomName] = useState<boolean>(false)

    const handleTextFieldChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        props.setValue(props.reduxKey, e.target.value)
        if (props.reduxKey === 'receiverAccountNumber') props.setValue('receiverNRB', e.target.value.substring(2, 11))
        setValue(e.target.value)
    }

    const handleDateFieldChange = (e: string): void => {
        props.setValue(props.reduxKey, e)
        setValue(e)
    }

    const populateEditor = (e: ChangeEvent<HTMLInputElement | any>) => {
        setValue(e.target.value)
        props.setSelectedCell(props.reduxKey)
    }

    const handleSelectValue = (index: number): void => {
        props.handleSetRow({ ...importedData[index], date: dayjs(date).format('YYYYMMDD'), amount: 0 })
    }

    const handleCustomSelect = (): void => {
        setIsCustomName(true)
        props.setSelectedCell('receiverName')
    }

    const handleFocus = (e: React.FocusEvent<any>): void => {
        populateEditor(e as any)
    }

    const getInputField = (key: string): JSX.Element => {
        if (!isCustomName && key === 'receiverName') return (
            <TextField variant={'standard'} select value={props.value}>
                {importedData.length > 0 && importedData.map((entry: IWire, index: number) => (
                    <MenuItem onClick={() => handleSelectValue(index)} value={entry.receiverName}>{entry.receiverName}</MenuItem>
                ))}
                <MenuItem value={'custom'} onClick={() => handleCustomSelect()}>Wpisz własny</MenuItem>
            </TextField>
        )
        if (key === 'date') return <input type='date' className={'date_picker'} value={dayjs(props.value).format('YYYY-MM-DD')} onClick={(e) => populateEditor(e)} onChange={(e) => handleDateFieldChange(dayjs(e.target.value).format('YYYYMMDD'))} onFocus={handleFocus} />
        if (key === 'amount') return <TextField type={'number'} value={props.value} variant={'standard'} onChange={(e) => handleTextFieldChange(e)} onClick={(e) => populateEditor(e)} onFocus={handleFocus} />
        return <TextField value={props.value} variant={'standard'} onChange={(e) => handleTextFieldChange(e)} onClick={(e) => populateEditor(e)} onFocus={handleFocus} />
    }

    if (props.isEditable) return (
        <TableCell className={'content_table_cell'}>
            <Box>
                {getInputField(props.reduxKey)}
            </Box>
        </TableCell>
    )

    const formatData = (type: string, value: any): string => {
        switch (type) {
            case 'amount':
                if (typeof value === 'number') {
                    const roundedValue = Math.round(value * 100) / 100
                    return roundedValue.toFixed(2) + ' zł'
                }
            case 'date':
              return dayjs(value).format('DD/MM/YYYY')
            default:
                return value
          }
    }

    return (
        <Tooltip title={props.value}>
            <TableCell className={'content_table_cell'}>{formatData(props.reduxKey, props.value)}</TableCell>
        </Tooltip>
    )
}

interface RowProps {
    index: number,
    wire: IWire,
    isEditable: boolean,
    setEditableRow: (arg: number) => void
}

const Row = ({ ...props }: RowProps): JSX.Element => {
    const dispatch = useDispatch()
    const { value, setValue } = useContext(EditorContext)
    const [rowState, setRowState] = useState<IWire>({ ...props.wire, amount: props.wire.amount / 100 })
    const [selectedKey, setSelectedKey] = useState<string>('')

    const handleCancel = (): void => {
        setRowState({ ...props.wire, amount: props.wire.amount / 100 })
        props.setEditableRow(-1)
        setSelectedKey('')
        setValue('')
    }

    const handleChangeValue = (): void => {
        let receiver: string = rowState.receiverName
        if (rowState.receiverName.length > 30 && !rowState.receiverName.includes('||')) {
            const newValue: string = rowState.receiverName
            const parts = []
            for (let i = 0; i < newValue.length; i += 30) {
                parts.push(newValue.slice(i, i + 30))
            }
            receiver = parts.join('||')
        }
        let sender: string = rowState.senderName
        if (rowState.senderName.length > 35) {
            const newValue: string = rowState.senderName.replaceAll('|', '')
            console.log(newValue)
            const parts = []
            for (let i = 0; i < newValue.length; i += 35) {
                parts.push(newValue.slice(i, i + 35))
            }
            sender = parts.join('|')
        }
        dispatch(changeRowData({ index: props.index, value: { ...rowState, amount: Math.round(rowState.amount * 100), receiverName: receiver, senderName: sender }}))
        props.setEditableRow(-1)
        setSelectedKey('')
        setValue('')
    }

    const handleStateChange = (valueKey: string, value: string | number): void => {
        if (selectedKey === valueKey) setRowState((prevRowState) => ({ ...prevRowState, [valueKey]: value }))
        setValue('')
    }

    const handleRowChange = (value: any): void => {
        setRowState(value)
    }

    const handleSetEditableRow = (index: number): void => {
        props.setEditableRow(index)
        setSelectedKey('')
        setValue('')
    }

    useEffect(() => {
        setRowState({ ...props.wire, amount: props.wire.amount / 100 })
    }, [props.wire, props.isEditable])

    useEffect(() => {
        if (props.isEditable) setRowState({ ...rowState, [selectedKey]: value })
    }, [value, selectedKey, setRowState])

    return (
        <TableRow>
            <Cell value={props.isEditable && selectedKey === 'receiverName' ? value : rowState.receiverName} reduxKey={'receiverName'} index={props.index} isEditable={props.isEditable} setValue={handleStateChange} setSelectedCell={setSelectedKey} handleCancel={handleCancel} handleSetRow={handleRowChange} />
            <Cell value={props.isEditable && selectedKey === 'receiverAccountNumber' ? value : rowState.receiverAccountNumber} reduxKey={'receiverAccountNumber'} index={props.index} isEditable={props.isEditable} setValue={handleStateChange} setSelectedCell={setSelectedKey} handleCancel={handleCancel} handleSetRow={handleRowChange} />
            <Cell value={props.isEditable && selectedKey === 'amount' ? value : rowState.amount} reduxKey={'amount'} index={props.index} isEditable={props.isEditable} setValue={handleStateChange} setSelectedCell={setSelectedKey} handleCancel={handleCancel} handleSetRow={handleRowChange} />
            <Cell value={props.isEditable && selectedKey === 'title' ? value : rowState.title} reduxKey={'title'} index={props.index} isEditable={props.isEditable} setValue={handleStateChange} setSelectedCell={setSelectedKey} handleCancel={handleCancel} handleSetRow={handleRowChange} />
            <Cell value={props.isEditable && selectedKey === 'date' ? value : rowState.date} reduxKey={'date'} index={props.index} isEditable={props.isEditable} setValue={handleStateChange} setSelectedCell={setSelectedKey} handleCancel={handleCancel} handleSetRow={handleRowChange} />
            <TableCell className={'content_table_buttons_cell'}>
                {props.isEditable ? (
                    <>
                        <IconButton onClick={handleChangeValue}>
                            <CheckIcon />
                        </IconButton>
                        <IconButton onClick={handleCancel}>
                            <CloseIcon />
                        </IconButton>
                    </>
                ) : (
                    <>
                        <IconButton onClick={() => handleSetEditableRow(props.index)}>
                            <EditIcon />
                        </IconButton>
                        <IconButton onClick={() => dispatch(deleteRow({ index: props.index }))}>
                            <DeleteIcon />
                        </IconButton>
                    </>
                )}
            </TableCell>
        </TableRow>
    )
}

const ContentTable: React.FC = () => {
    const dataSet = useSelector(selectData)
    const dispatch = useDispatch()
    const importedData = useSelector(selectImportedData)
    const [editableRow, setEditableRow] = useState<number>(-1)
    const [counter, setCounter] = useState<number>(!!dataSet ? dataSet.length : 0)

    const handleNewRow = (wire: IWire) => {
        setCounter((c) => c += 1)
        setEditableRow(counter)
        dispatch(addEntry({ ...defaultEntry, NRB: wire.NRB, senderAccountNumber: wire.senderAccountNumber, senderName: wire.senderName }))
    }

    useEffect(() => {
        setCounter(!!dataSet ? dataSet.length : 0)
    }, [dataSet])

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: '100%', tableLayout: 'fixed' }}>
                <TableHead className={'content_table_head'}>
                    <TableRow>
                        <TableCell variant={'head'}>Kontrahent</TableCell>
                        <TableCell variant={'head'}>Numer rachunku odbiorcy</TableCell>
                        <TableCell variant={'head'}>Kwota</TableCell>
                        <TableCell variant={'head'}>Tytuł</TableCell>
                        <TableCell variant={'head'}>
                            <p className={'content_table_info_cell'}>
                                Data
                                <Tooltip title={'Format daty: RRRRMMDD'} sx={{ ml: 1 }}>
                                    <InfoIcon />
                                </Tooltip>
                            </p>
                        </TableCell>
                        <TableCell variant={'head'}>Opcje</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody className={'content_table_tbody'}>
                    {dataSet && dataSet.map((wire, index) => (
                        <Row key={index} index={index} wire={wire} isEditable={editableRow === index} setEditableRow={setEditableRow} />
                    ))}
                </TableBody>
            </Table>
            <Button disabled={importedData.length === 0 || editableRow != -1} className={'content_table_add_button'} onClick={() => handleNewRow(importedData[0])}>dodaj</Button>
        </TableContainer>
    )
}

export default ContentTable